import logo from './img/logo.svg';
import './App.css';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='React' />
        <p>choka.storeall.com.ua in progress...</p>
      </header>
    </div>
  );
}

export default App;
